import { ModalWindow } from "../../UI/ModalWindow";
import { Input, Select } from '../../UI/FormElements'

const ROUTE_URL = "/instructions";
const initialData = { completion_at: "", responsible_two: "", incident_type: "", department: "", comment: "", status: "новое" };

function ModalInstructions({ params, handleClose }) {
  console.log("params", params);
  let urlSubmit, urlGet;
  if (params.isNew) {
    urlSubmit = ROUTE_URL;
    urlGet = ""; //или ROUTE_URL + "/create" если при создании НАДО предварительное обращение к серверу

  }
  else {
    urlGet = ROUTE_URL + "/" + params.id;
    urlSubmit = urlGet;

  }

  console.log(urlGet);
  return (
    <ModalWindow params={params} handleClose={handleClose} initialData={initialData} urlGet={urlGet} urlSubmit={urlSubmit} >
      <IncidentCardsFields />
    </ModalWindow>
  );
}
let categoryTypes = [{ id: 1, name: "Новое" }, { id: 2, name: "В работе" }, { id: 3, name: "Просрочено" }, { id: 4, name: "Выполнено" }, { id: 5, name: "В архиве" }];

const IncidentCardsFields = ({ dopParams, ...elementProps }) => {
  console.log(elementProps);
  return (
    <div className="row form-gutter">
      <div className="col-12">
        <Input label="Дата завершения" name="completion_at"  {...elementProps} required autoFocus />
      </div>
      <div className="col-12">
        <Input label="Ответственный сотрудник" name="responsible_two"  {...elementProps} required />
      </div>
      <div className="col-12">
        <Input label="Тип инцидента" name="incident_type" {...elementProps} required />
      </div>
      <div className="col-12">
        <Input label="Описание" name="department" {...elementProps} required />
      </div>
      <div className="col-12">
        <Input label="Комментарий" name="comment" {...elementProps} required />
      </div>
      <div className="col-12">
        <Select label="Статус" name="status" {...elementProps} selectOptions={categoryTypes} required />
      </div>
    </div>
  )
}
export default ModalInstructions;
